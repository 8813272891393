import React from 'react'
import App from 'App'
import { Widget } from '@typeform/embed-react'
import { COLOR_CONSTANTS } from 'theme'
import SEO from 'components/SEO'
import { Box, Flex } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Footer from 'components/Footer'

const Nonprofits = () => {
  return (
    <App>
      <SEO
        title="Vista Social for Nonprofits"
        description="The most complete social media management platform for non profits"
        path="/nonprofits/"
      />
      <Flex mt="xl" px="m" alignItems="center" flexDirection="column" textAlign="center">
        <H1 color={COLOR_CONSTANTS.DENIM} fontSize="4xl" fontWeight="bold">
          Vista Social Loves Nonprofits!
        </H1>
        <Box mx="auto" maxWidth="780px">
          <Flex flexDirection="column" mt="m">
            <Text textAlign="left" color="primaryText" fontWeight="bold" fontSize="m" mt="l">
              All Vista Social plans are 50% off for nonprofits
            </Text>
            <Text color="secondaryText" fontSize="m" textAlign="left" mt="m">
              There are so many great nonprofits working hard to make the world a better place. We want to help a tiny
              bit when it comes to their social media marketing efforts.
            </Text>
            <Text textAlign="left" color="primaryText" fontWeight="bold" fontSize="m" mt="l">
              Is my organization eligible for a nonprofit discount?
            </Text>
            <Text color="secondaryText" fontSize="m" textAlign="left" mt="m">
              We offer a 50% discount to all registered nonprofit organizations. All we need to see is your official
              documentation, such as a copy of your 501(c)(3), or your country’s equivalent, to support your nonprofit
              status.
            </Text>
            <Text textAlign="left" color="primaryText" fontWeight="bold" fontSize="m" mt="l">
              How do I apply for the Non-Profit discount?
            </Text>
            <Text color="secondaryText" fontSize="m" textAlign="left" mt="m">
              To apply for a nonprofit discount for your organization, please use the link below to submit your
              nonprofit documentation. Once we've received your documentation, we'll apply the 50% discount to all
              future payments. If you already made the upgrade, that’s no problem. Let us know and we'll refund your
              most recent payment. 😃
            </Text>
            <Text textAlign="left" color="primaryText" fontWeight="bold" fontSize="m" mt="l">
              Ready to apply for the discount? Please fill complete the form below.
            </Text>
            <Box width="100%" minHeight="520px" mt="l">
              <Widget id="TVdRfJnH" style={{ width: '100%', height: '550px' }} className="my-form" />
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Footer />
    </App>
  )
}

export default Nonprofits
